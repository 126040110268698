<template>
  <v-dialog v-model="visible" persistent max-width="800">
    <v-card class="pa-5 rounded-lg" id="card-custom">
      <v-btn @click="visible = false" x-small icon class="float-right">
        <v-icon>close</v-icon>
      </v-btn>
      <v-card-text class="subtitle-2 px-0 font-weight-regular">
        Presensi
      </v-card-text>
      <v-divider class="mb-4" />
      <v-form
        id="form"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="save()"
      >
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Tanggal</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${displayAttendaceDate}`"
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >Nama/NIP</v-subheader
            >
          </v-col>
          <v-col cols="9" class="py-1">
            <v-text-field
              :value="`${form.nama} - ${form.NIP}`"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-btn-toggle
          v-model="form.types"
          class="ml-3 mb-3"
          id="btn-type"
          mandatory
        >
          <v-btn small color="#1792E6" class="px-8 py-2">
            <span class="text-capitalize font-weight-regular"
              >Presensi Masuk</span
            >
          </v-btn>
          <v-btn small color="#1792E6" class="px-8 py-2">
            <span class="text-capitalize font-weight-regular"
              >Presensi Keluar</span
            >
          </v-btn>
        </v-btn-toggle> -->
        <v-row v-if="showList">
          <v-col cols="12" class="py-1">
            <v-subheader class="caption headline-color font-weight-regular"
              >List Absensi</v-subheader
            >
            <v-card-actions>
              <v-spacer />
              <v-menu
                ref="menu"
                v-model="menu"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    placeholder="Tanggal"
                    append-icon="event"
                    outlined
                    dense
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  locale="id"
                  no-title
                  scrollable
                ></v-date-picker>
              </v-menu>
            </v-card-actions>
            <v-data-table
              id="table-custom-pegawai"
              class="mt-3"
              :headers="attendanceHeader"
              :items="historyAttendances"
              hide-default-footer
            >
            </v-data-table>
          </v-col>
        </v-row>
        <!-- <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#717171"
            class="px-12"
            @click="visible = false"
            >Batal</v-btn
          >
          <v-btn
            small
            color="#FBB005"
            class="px-12 subtitle-2 text-capitalize"
            :loading="formLoading"
            type="submit"
            :disabled="!valid"
            form="form"
            >Submit</v-btn
          >
        </v-card-actions> -->
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import AttendanceService from "@/services/resources/attendance.service";
import { getCurrentPosition } from "@/util/geolocation";

export default {
  props: {
    showList: { type: Boolean, required: false, default: true }
  },
  data() {
    return {
      visible: false,
      valid: false,
      formLoading: false,
      loading: false,
      types: [],
      menu: false,
      date: new Date().toISOString().substr(0, 10),
      form: {
        nama: null,
        NIP: null,
        attendance_date: null,
        types: 0
      },
      currentLocation: {
        latitude: null,
        longitude: null
      },
      historyAttendances: [],
      attendanceHeader: [
        {
          text: "Akses",
          value: "attend_for_name",
          sortable: false,
          align: "center"
        },
        {
          text: "Jam",
          value: "attend_time",
          sortable: false,
          align: "center"
        },
        {
          text: "Tipe",
          value: "attend_type_name",
          sortable: false,
          align: "center"
        }
      ]
    };
  },
  watch: {
    visible(val) {
      if (!val) {
        this.form.attendance_date = null;
        this.form.nama = null;
        this.form.NIP = null;
        this.form.types = 0;
        this.$refs.form.reset();
      }
    },
    date() {
      this.getLogAbsensi();
    }
  },
  computed: {
    displayAttendaceDate() {
      return this.$options.filters.dateDisplay(this.form.attendance_date);
    }
  },
  methods: {
    insert(item) {
      this.form.attendance_date = item.attendance_date;
      this.form.nama = item.nama;
      this.form.NIP = item.NIP;
      this.visible = true;
      this.date = item.attendance_date;
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$confirm({
          title: "Konfirmasi",
          message: `Apakah anda yakin akan absen <b>${
            this.form.types ? "Keluar" : "Masuk"
          }</b> pada tanggal <b>${this.$options.filters.date(
            this.form.attendance_date
          )}</b>?`,
          button: {
            no: "Batal",
            yes: "Ya"
          },
          callback: confirm => {
            const { latitude, longitude } = this.currentLocation;
            if (confirm) {
              this.checkInAttendance({
                NIP: this.form.NIP,
                attend_type: this.form.types ? "100002" : "100001",
                attend_date: this.form.attendance_date,
                attend_time: this.$moment().format("HH:mm:ss"),
                lat: latitude,
                lng: longitude
              });
            }
          }
        });
      }
    },
    getPosition(pos) {
      const { latitude, longitude } = pos.coords;
      this.currentLocation.latitude = latitude;
      this.currentLocation.longitude = longitude;
    },
    // Service
    async getLogAbsensi() {
      try {
        this.loading = true;
        await AttendanceService.logAbsensi({
          NIP: this.form.NIP,
          attend_date: this.$moment(this.date).format("YYYYMMDD")
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.historyAttendances = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async checkInAttendance(payload) {
      try {
        this.formLoading = true;
        await AttendanceService.absensiWeb(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$emit("on-refresh");
              this.visible = false;
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    getCurrentPosition(this.getPosition);
  }
};
</script>
<style lang="scss">
#btn-type .v-btn--contained {
  background-color: #ffffff !important;
  .v-btn__content {
    color: #717171 !important;
  }
}
#btn-type .v-btn--active {
  background-color: #1792e6 !important;
  .v-btn__content {
    color: #ffffff !important;
  }
}
</style>
